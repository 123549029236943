<template>
  <div id="app">
    <div class="container" v-if="curfile.preview">
      <div class="jobbox">
        <!-- <div id="output" class="progcontainer">{{ this.$store.state.curfile.status }} </div> -->
        <div class="fileinfo">
          <div>
            <b>Filename</b> <br />
            <!-- {{ this.$store.state.curfile.internamFn }} -->
            {{ curfile.fname + curfile.fext }}
            <br />
            <br />
            <b>File Size</b><br />
            - {{ this.$store.state.curfile.fsize }} <b>KB</b>
            <br />
            <br />
          </div>
          <b>Separations</b>
          <p v-for="sepname in curfile.sepnames" :key="sepname.index">
            - {{ sepname }}
          </p>
          <br />
          <b>Dimensions</b> ({{ curfile.uom }})<br />
          - Width: {{ Math.round(curfile.hsize * 100) / 100 }} <br />
          - Height: {{ Math.round(curfile.vsize * 100) / 100 }} <br />
          <br />
          <b>Default Mask:</b> {{ curfile.maskwidth / 10 }} pts <br />
          <br />
          <b> Custom Mask: </b>

          <input
            v-model="maskwidth"
            @change="handleMaskwidth"
            style="width: 1.5em"
          />
          pts
        </div>
        <!-- end seps -->
        <div class="preview">
          <img id="myprev" :src="'data:image/jpeg;base64,' + curfile.preview" />
        </div>
        <!-- end preview -->
        <div>
          <button class="mybutton" v-on:click="handleclear">New</button>
          <button class="mybutton" v-on:click="handleUploadBtn">Upload</button>
        </div>
        <div id="output" class="progcontainer">
          <b>Status:</b> {{ this.$store.state.curfile.status }}
        </div>
        <div class="debugdata">
          <b> Debug Data </b>
          <ul>
            <li>File Name: {{ curfile.fname + curfile.fext }}</li>
            <li>Status: {{ curfile.status }}</li>
            <li>Size: {{ curfile.fsize }} kb</li>
            <li>
              file handle name: {{ this.$store.state.curfile.filehandle.name }}
            </li>
            <li>Time: {{ curfile.time }}</li>
            <li>Separations: {{ curfile.sepnames }}</li>
            <li>
              Preview:
              <img
                id="myprev"
                :src="'data:image/jpeg;base64,' + curfile.preview"
              />
            </li>
            <li>Width: {{ curfile.phsize }}</li>
            <li>Height: {{ curfile.pvsize }}</li>
            <li>File Width: {{ curfile.hsize }}</li>
            <li>File Height: {{ curfile.vsize }}</li>
            <li>Units: {{ curfile.uom }}</li>
            <li>Mediabox: {{ curfile.mediabox }}</li>
            <li>Artbox: {{ curfile.artbox }}</li>
            <li>PdfSeps: {{ curfile.pdfsepdefs }}</li>
            <li>Mask Width: {{ curfile.maskwidth }}</li>
            <li>swatches: {{ curfile.swatchgroup }}</li>
            <li>CheckS3Uri: {{ curfile.chks3uri }}</li>
            <li>Json Job: {{ curfile.jobticket }}</li>
            <li>Uploads3uriBucket: {{ curfile.ups3uriBucket }}</li>
            <li>Uploads3uriKey: {{ curfile.ups3uriKey }}</li>
            <li>UploadS3uri: {{ curfile.ups3uri }}</li>
            <li>Presignbucket: {{ curfile.uppsUriBucket }}</li>
            <li>PresignedKey: {{ curfile.uppsUriKey }}</li>
            <li>curfile.uppsUri: {{ curfile.uppsUri }}</li>
            <li>Mask Dl S3 Uri: {{ curfile.dlpsUri }}</li>
          </ul>
        </div>
      </div>
      <!-- end jobbox -->
    </div>
    <div class="container" v-else>
      <app-fileupload></app-fileupload>
    </div>
  </div>
</template>

<script>
import AppFileupload from "@/components/AppFileupload";
import store from "@/store/index.js"; // top level store object
import axios from "axios";
import path from "path";
export default {
  name: "App",
  data() {
    return {
      uploadPercentage: 0,
      maskwidth: 0,
    };
  },
  store,
  headingText: "",

  components: {
    AppFileupload,
  },
  computed: {
    authenticated() {
      return this.$store.state.authenticated;
    },
    curfile() {
      return this.$store.state.curfile;
    },
    userinfo() {
      return this.$store.state.userinfo;
    },
    config() {
      return this.$store.state.config;
    },
  },
  methods: {
    handleMaskwidth() {
      this.curfile.maskwidth = this.maskwidth * 10;
    },

    handleclear() {
      this.$store.state.curfile.preview = "";
      this.$store.state.curfile.inputPdfFile = "";
    },
    handleUploadBtn() {
      this.handleCheckExists();
    }, // end handleUpload

    async handleCheckExists() {
      // Checkexists
      var data = this.curfile.chks3uri;
      this.curfile.status = "Checking if this file was previously uploaded";
      var config = {
        method: "post",
        url: "https://flexomask.net/checkexists.php",
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          // accept returned object in case iterated
          this.curfile.ups3uriBucket = response.data.bucket;
          this.curfile.ups3uriKey = response.data.key;
          this.curfile.ups3uri =
            "s3://" + path.join(response.data.bucket, response.data.key);
          this.handleGetPresigned();
        })
        .catch(function (error) {
          console.log(error);
        });
    }, // end handleCheckExists
    async handlejtCheckExists() {
      // Checkexists
      var data = this.curfile.jtchks3uri;
      this.curfile.status =
        "Checking if this Job ticket was previously uploaded";
      var config = {
        method: "post",
        url: "https://flexomask.net/checkexists.php",
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          // accept returned object in case iterated
          this.curfile.jts3uriBucket = response.data.bucket;
          this.curfile.jts3uriKey = response.data.key;
          this.curfile.jts3uri =
            "s3://" + path.join(response.data.bucket, response.data.key);
          this.handleGetjtPresigned();
        })
        .catch(function (error) {
          console.log(error, this.curfile.jts3uri);
        });
    }, // end handlejtCheckExists,

    async handleGetPresigned() {
      let bucket = this.curfile.ups3uriBucket;
      let key = this.curfile.ups3uriKey;
      var data = {
        bucket: bucket,
        key: key,
      };
      this.curfile.status = "Generating Secure Url";
      var apiEndpoint =
        "https://al5xp48bdi.execute-api.us-east-1.amazonaws.com/test/helloworld/";

      var config = {
        method: "put",
        url: apiEndpoint,
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          this.curfile.uppsUri = response.data.link;
          this.curfile.status = "Secure Url acquired";
        })
        .catch(function (error) {
          alert("Error getting presigned Url: " + error);
        });
      this.curfile.jobticket = {
        s3uri: this.curfile.ups3uri,
        id: this.curfile.ups3uri.split("/").pop().split(".")[0], // get iterated filename without extension
        co: this.userinfo.co.replace(/[^a-z0-9.]/gi, "_"),
        site: this.userinfo.site.replace(/[^a-z0-9.]/gi, "_"),
        user: this.userinfo.uemail.replace(/[^a-z0-9.]/gi, "_"),
        bucket: this.config.uploads3,
        fname: this.curfile.fname,
        fext: this.curfile.fext,
        seps: this.curfile.sepnames,
        maskwidth: this.curfile.maskwidth,
        hsize: this.curfile.hsize,
        vsize: this.curfile.vsize,
        fsize: this.curfile.fsize,
      };
      this.uploads3();
    }, //end getPresigned method
    async handleGetjtPresigned() {
      let bucket = this.curfile.jts3uriBucket;
      let key = this.curfile.jts3uriKey;
      var data = {
        bucket: bucket,
        key: key,
      };
      this.curfile.status = "Generating Job Ticket Secure Url";
      var apiEndpoint =
        "https://al5xp48bdi.execute-api.us-east-1.amazonaws.com/test/helloworld/";

      var config = {
        method: "put",
        url: apiEndpoint,
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          this.curfile.jtpsUri = response.data.link;
          this.curfile.status = "Secure Job Ticket Url acquired";
        })
        .catch(function (error) {
          alert("Error getting presigned Url: " + error);
        });
      this.uploadjts3();
    }, //end getjtPresigned method,

    async uploads3() {
      // var output = document.getElementById('output');
      this.$store.state.curfile.status = "Uploading this file to the cloud";
      var config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.$store.state.curfile.status =
            "Uploading " + percentCompleted + "%";
        }.bind(this),
        method: "put",
        url: this.curfile.uppsUri,
        // data: formData,
        data: this.$store.state.curfile.inputPdfFile,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      };

      await axios(config)
        .then(() => {
          this.$store.state.curfile.status = "Pdf File Upload Complete";
          this.handlejtCheckExists();
        })
        .catch(function (error) {
          alert("Error uploading: " + error);
        });
    }, // end uploads3
    async uploadjts3() {
      // var output = document.getElementById('output');
      this.$store.state.curfile.status =
        "Uploading the Job Ticket to the cloud";
      var config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.$store.state.curfile.status =
            "Uploading " + percentCompleted + "%";
        }.bind(this),
        method: "put",
        url: this.curfile.jtpsUri,
        // data: formData,
        data: this.$store.state.curfile.jobticket,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      };

      await axios(config)
        .then(() => {
          this.$store.state.curfile.status = "Job Ticket Uploaded";
          this.submitJt();
        })
        .catch(function (error) {
          alert("Error uploading: " + error);
        });
    }, // end uploadjts3,
    async submitJt() {
      // submit jobticket for processing
      var data =
        "s3://" +
        path.join(this.curfile.jts3uriBucket, this.curfile.jts3uriKey);
      // var data = this.curfile.jtchks3uri;
      this.curfile.status = "Processing Job";
      var config = {
        method: "post",
        url: "https://flexomask.net/main.php",
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };
      await axios(config)
        // .then((response) => {
        .then(() => {
          // accept returned object in case iterated
          // this.curfile.jts3uriBucket = response.data.bucket;
          // this.curfile.jts3uriKey = response.data.key;
          // this.curfile.jts3uri =
          //  "s3://" + path.join(response.data.bucket, response.data.key);
          // this.curfile.status = response.data;
          this.curfile.status = "Masks file ready";
        })
        .catch(function (error) {
          console.log(error, this.curfile.jtchks3uri);
        });
    }, // end submitJt,
  },
  // end methods
};
</script>

<style>
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.debugdata {
  border-radius: 0.25rem;
  border: 1px solid#486684;
  /* display: flex; */
  display: none;
  /* margin: auto; */
  margin-top: 7rem;
  width: auto;
  float: left;
  padding: 2rem;
}
.debugdata ul {
  list-style: none;
  padding: 10px;
}

.jobbox {
  border-radius: 0.25rem;
  display: flexbox;
  text-align: left;
  /* border: 1px solid#464943; */
  box-shadow: 0rem 0.1rem 0.1rem 0.1rem rgb(184, 182, 182);
  /* display: block; */
  margin: auto;
  width: 53rem;
  min-width: 45rem;
  /* min-height: 50vh; */
  height: 31rem;
  padding: 5rem;
  background-color: rgb(210, 210, 210);
}

#output {
  display: flexbox;
  width: 48rem;
  /* border: .5px solid#464943; */
  /* height: .25rem; */
  /* padding: 1rem; */
  /* margin-bottom: 0rem;
	margin-top: 0rem; */
}

.blue {
  color: #55f;
}
.preview {
  background-color: #fefefe;
  display: table-cell;
  height: 300px;
  width: 300px;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.1rem 0.1rem 0.1rem rgb(184, 182, 182);
}

.mybutton {
  background-color: transparent;
  border: 0px solid #aaa;
  border-color: #aaa;
  border-radius: 0.25rem;
  color: #888;
  cursor: pointer;
  font-weight: 800;
  margin: 5rem 3rem 3rem 0rem;
  padding: 0.5rem 1rem;
  box-shadow: 0rem 0.1rem 0.1rem 0.1rem rgb(184, 182, 182);
  background-color: rgb(240, 240, 240);
}

.mybutton:hover {
  background-color: #44ac14;
  color: #333;
  transition: 0.25s all;
}
.mybutton:active {
  background-color: green;
  color: #eee;
  border-color: #555;
  box-shadow: 0rem 0.05rem 0.05rem 0.05rem rgb(184, 182, 182);
}
.fileinfo {
  display: flexbox;
  border-radius: 0.25rem;
  overflow-wrap: normal;
  width: 24rem;
  height: 30rem;
  float: right;
  text-align: left;
  /* background-color: #e0e0e0; */
  padding: 1rem 2rem 0rem 3rem;
  box-shadow: 0rem 0.1rem 0.1rem 0.1rem rgb(184, 182, 182);
  background-color: rgb(240, 240, 240);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.bodyclass {
  background-color: rgb(250, 245, 245);
  /* background-image: url('./img/seamlessregmark50.jpg'); */
}
.container {
  display: block;
  margin: auto;
}
</style>
